import * as bootstrap from 'bootstrap';

export default class SiteHeader extends HTMLElement {

    constructor() {
        super()

        console.log("SiteHeader.js")

        this.backdrop = this.querySelector('.backdrop')
        this.mainNavOpened = false
        this.mainNav = this.querySelector('.main-nav')
        this.searchPanelOpened = false
        this.searchPanel = this.querySelector('.search-panel')
        this.toggleSearchPanelLinks = document.querySelectorAll('[data-toggle="search-panel"]')
        this.toggleMainNavLinks = document.querySelectorAll('[data-toggle="main-nav"]')
    }

    connectedCallback() {

        this.toggleMainNavLinks.forEach(toggleMainNavLink => {
            toggleMainNavLink.addEventListener('click', (e) => {
                e.preventDefault()
                if (this.mainNavOpened) {
                    this.closeMainNav()
                } else {
                    this.openMainNav()
                }
            })
        })

        this.toggleSearchPanelLinks.forEach(toggleSearchPanelLink => {
            toggleSearchPanelLink.addEventListener('click', (e) => {
                e.preventDefault()
                if (this.searchPanelOpened) {
                    this.closeSearchPanel()
                } else {
                    this.openSearchPanel()
                    // If target tab is requested
                    if (e.target.dataset.targetTab) {
                       const tab = document.querySelector(e.target.dataset.targetTab)
                        if (tab && bootstrap.Tab.getInstance(tab)) {
                            bootstrap.Tab.getInstance(tab).show()
                        }
                    }
                }
            })
        })

        this.backdrop.addEventListener('click', (e) => {
            if (this.mainNavOpened) {
                this.closeMainNav('close')
            }
            if (this.searchPanelOpened) {
                this.closeSearchPanel('close')
            }
        })

    }

    openMainNav() {
        if (this.searchPanelOpened) {
            this.closeSearchPanel()
        }
        this.mainNav.classList.add('opened')
        this.mainNavOpened = true

        this.toggleMainNavLinks.forEach(toggleMainNavLink => {
            toggleMainNavLink.classList.add('active')
        })

        this.toggleBackdrop()
    }

    closeMainNav() {
        this.mainNav.classList.remove('opened')
        this.mainNavOpened = false

        this.toggleMainNavLinks.forEach(toggleMainNavLink => {
            toggleMainNavLink.classList.remove('active')
        })

        this.toggleBackdrop()
    }

    openSearchPanel() {
        if (this.mainNavOpened) {
            this.closeMainNav()
        }
        this.searchPanel.classList.add('opened')
        this.searchPanelOpened = true
        this.toggleBackdrop()
    }

    closeSearchPanel() {
        this.searchPanel.classList.remove('opened')
        this.searchPanelOpened = false
        this.toggleBackdrop()
    }

    toggleBackdrop() {
        if (this.mainNavOpened || this.searchPanelOpened) {
            this.backdrop.classList.add('show')
        } else {
            this.backdrop.classList.remove('show')
        }
    }

}