import markerImg from '../../img/custom-map-marker.svg';
import markerImgActive from '../../img/custom-map-marker-active.svg';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default class AssosLocator extends HTMLDivElement {

    constructor() {
        super();
        this.assoItems = this.querySelectorAll('.assos-list-item');
        this.mapContainer = this.querySelector('.map');
        this.assosDetails = this.querySelectorAll('.assos-details');
        this.searchInput = this.querySelector('#input-search');
        this.icon = null;
        this.iconActive = null;
        this.map = null;
        this.markers = [];


        this.markersDatas = [];

    }

    connectedCallback() {

        this.markersDatas = $('#jsonAssociations').data().json;

        this.assoItems.forEach(assoItem => {
            assoItem.addEventListener('click', (e) => {
                this.setCurrentAsso(parseInt(e.currentTarget.dataset.index));
            });
        });

        // Evenement pour la recherche d'une association
        this.searchInput.addEventListener("input", (e) => {

            let value = e.target.value

            if (value && value.trim().length > 0) {

                // on cache tous les elements de la liste
                $('.assos-list-item').hide();

                // si on a aucun r�sultat on affiche aucun resultat sinon on affiche les elements qui correspondent � la recherche
                if ($('.assos-list-item:icontains(' + value + ')').length == 0) {
                    $('#noResult').show();
                } else {
                    $('#noResult').hide();
                }
                $('.assos-list-item:icontains(' + value + ')').show();

            } else {
                // on affiche tout vu que y a plus rien comme recherche
                $('.assos-list-item').show()

            }

        });

        this.map = L.map(this.mapContainer).setView([48.111778, -1.680260], 12);

        L.control.zoom({
            position: 'topright'
        }).addTo(this.map);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        this.icon = L.icon({
            iconUrl: markerImg,
            iconSize: [30, 45],
            iconAnchor: [15, 28]
        });

        this.iconActive = L.icon({
            iconUrl: markerImgActive,
            iconSize: [30, 45],
            iconAnchor: [15, 28]
        });

        this.markersLayer = L.layerGroup().addTo(this.map);

        this.markersDatas.forEach(markerData => {
            const marker = L.marker([markerData.lat, markerData.lng], {
                icon: this.icon,
                index: markerData.index
            });

            marker.on('click', () => {
                this.setCurrentAsso(markerData.index);
            });

            this.markersLayer.addLayer(marker);
            this.markers.push(marker);
        });

        const bounds = this.calculateMarkersBounds();
        this.map.fitBounds(bounds);
    }

    setCurrentAsso(index) {
        this.assoItems.forEach(assoItem => {
            assoItem.classList.toggle('active', parseInt(assoItem.dataset.index) === index);
        });

        this.assosDetails.forEach(assoItem => {
            assoItem.classList.toggle('active', parseInt(assoItem.dataset.index) === index);
        });

        this.markers.forEach(marker => {
            if (marker.options.index === index) {
                marker.setIcon(this.iconActive);
                this.map.panTo(marker.getLatLng());
                //this.assosDetails.classList.add('active');
            } else {
                marker.setIcon(this.icon);
            }
        });
    }

    calculateMarkersBounds() {
        const bounds = L.latLngBounds();

        this.markersDatas.forEach(markerData => {
            bounds.extend([markerData.lat, markerData.lng]);
        });

        return bounds;
    }

}