export default class Recherche extends HTMLDivElement {

    constructor() {
        super();
        this.linksFiltersOffres = this.querySelectorAll('.js-filter-offre');
        this.typeOffreSelect = this.querySelector('#typeOffreSelect');
        this.publicViseSelect = this.querySelector('#publicViseSelect');
        this.secteurSelect = this.querySelector('#secteurSelect');
        this.handicapSelect = this.querySelector('#handicapSelect');
        this.ageSelect = this.querySelector('#ageSelect');
        this.tags = this.querySelectorAll('.tag');
       
    }

    connectedCallback() {

        if (this.linksFiltersOffres) {
            this.linksFiltersOffres.forEach(linkFilterOffre => {
                linkFilterOffre.addEventListener("click", (e) => {
                    var typeOffre2Selected = e.currentTarget.dataset.id;

                    $('.js-filter-offre').removeClass('active');
                    $(e.currentTarget).addClass('active');

                    $('.card-event').parent().show();

                    if (typeOffre2Selected != -1) {
                        $('.card-event').parent().hide();
                        $('.tag[data-offre2id=' + typeOffre2Selected + ']').parent().parent().parent().show();
                    }
                });
            })
        }

        if (this.typeOffreSelect) {

            if (this.publicViseSelect) {
                this.callDataSelect(this.publicViseSelect)
            }

            this.callDataSelect(this.typeOffreSelect)
            this.callDataSelect(this.secteurSelect)
            this.callDataSelect(this.handicapSelect)
            this.callDataSelect(this.ageSelect)
        }
    }


    callDataSelect(idSelect) {
        $.ajax({
            url: $(idSelect).data().url,
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                // Remplir les options dans l'�l�ment select
                var select = $(idSelect);
                $.each(data, function (index, item) {
                    select.append($('<option>', {
                        value: item.Value,
                        text: item.Text
                    }));
                });
            },
            error: function (error) {
            }
        });
    }
}