export default class AccessibilityTools extends HTMLDivElement {

  constructor() {
    super()
    this.fontSizeFactor = 1
    this.fontSizeFactorMin = 1
    this.fontSizeFactorMax = 1.5
    this.increaseSizeBtn = this.querySelector('.font-size-plus')
    this.decreaseSizeBtn = this.querySelector('.font-size-minus')
  }

  connectedCallback() {

    this.increaseSizeBtn.addEventListener('click', (e) => {
      e.preventDefault()
      if (this.fontSizeFactor < this.fontSizeFactorMax) {
        this.setFontSize(this.fontSizeFactor + 0.2)
      }
    })

    this.decreaseSizeBtn.addEventListener('click', (e) => {
      e.preventDefault()
      if (this.fontSizeFactor > this.fontSizeFactorMin) {
        this.setFontSize(this.fontSizeFactor - 0.2)
      }
    })

  }

  setFontSize(size) {
    this.fontSizeFactor = size
    document.documentElement.style.setProperty('--font-size-factor', size)
  }

}