import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import './scss/main.scss';

import 'bootstrap';

// Custom elements polyfill

import '@ungap/custom-elements';

import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

// Mount auto-sliders

import Splide from '@splidejs/splide';

// Custom elements

import AccessibilityTools from "./js/components/AccessibilityTools";
import AssosLocator from "./js/components/AssosLocator";
import SiteHeader from "./js/components/SiteHeader";
import Recherche from "./js/components/Recherche";

document.addEventListener("DOMContentLoaded", ( ) => {

    customElements.define("accessibility-tools", AccessibilityTools, { extends: "div" });
    customElements.define("assos-locator", AssosLocator, { extends: "div" });
    customElements.define("site-header", SiteHeader, { extends: "header" });
    customElements.define("recherche-offre-event", Recherche, { extends: "div" });

    const elems = document.querySelectorAll('.splide');
    elems.forEach(elem => {
        new Splide(elem).mount();
    });
})


// creation d'un contains non sensible � la casse
jQuery.expr[':'].icontains = function (a, i, m) {
    return jQuery(a).text().toUpperCase()
        .indexOf(m[3].toUpperCase()) >= 0;
};


$(() => {

    // Gestion des lien d'impression
    $(".print").on('click', function () {
        window.print();
    });

});